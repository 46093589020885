.contentContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 20px;

  .title {
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    margin-bottom: 10px;
    margin-top: 40px;
  }

  .description {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.2px;
    color: rgba(#FFFFFF, 0.8);
    margin-bottom: 30px;
    margin-left: 25px;
    margin-right: 25px;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    input {
      background: rgba(#FFFFFF, 0.07);
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2px;
      color: #FFFFFF;

      &::placeholder {
        font-weight: normal;
      }
    }

    :global {
      .password-container > .eye {
        path {
          stroke: white;
        }

        circle {
          fill: white;
        }
      }
    }

    .inputContainer {
      position: relative;
      display: flex;

      input {
        flex: 1;
        padding: 0 60px 0 20px;
      }

      .delete {
        position: absolute;
        top: 0;
        right: 22px;
        bottom: 0;
        margin: auto 0;
        height: 24px;
      }
    }

    .errorLabel {
      font-size: 12px;
      line-height: 22px;
      color: #e34646;
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  button {
    &:disabled {
      background: linear-gradient(
        270deg,
        rgba(178, 80, 255, 0.3) 0%,
        rgba(128, 45, 246, 0.3) 100%
      );
      color: rgba(#FFFFFF, 0.3);
    }
  }

  .legal {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(#FFFFFF, 0.5);
    margin-top: 18px;
    margin-bottom: 12px;

    a {
      color: rgba(#FFFFFF, 0.5);
    }
  }

  .disclaimer {
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(#FFFFFF, 0.5);
  }
}

.contentContainer {
  max-width: 335px; 
  margin-top: 30px;

  .title {
    margin-top: 0;
    font-size: 48px;
    margin-bottom: 30px;
  }
}
