@import "../../../../variables";

.letter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 625px;
  border-radius: 24px;
  margin: 145px 20px 140px;
  box-sizing: border-box;
  position: relative;

  &.red  {
    p {
      .accent {
        color: #FF0D38;
      }
    }

    span {
      color: #FF0D38;
    }
  }

  h3 {
    font-size: 32px;
    line-height: 44px;
    font-weight: 600;
    text-align: center;
  }

  p {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    margin-bottom: 15px;
    white-space: pre-line;

    .accent {
      color:#45B8AF;
      font-weight: 600;
    }
  }

  .sign {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 45px;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 18px;
      margin-bottom: 0;
    }

    .logo {
      svg {
        width: 158px;
        height: 36px;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .letter {
    max-width: 320px;
    margin-top: 50px;
    margin-bottom: 60px;

    h3 {
      font-size: 24px;
      line-height: 31px;
      font-weight: 300;
    }

    p {
      font-size: 18px;
      line-height: 23px;
    }

    .sign {
      margin-top: 40px;

      .title {
        font-size: 14px;
      }

      .logo {
        svg {
          width: 105px;
          height: 24px;
        }
      }
    }
  }
}