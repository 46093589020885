@import "../../../variables";

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 150px;
    overflow-x: hidden;
    position: relative;

    .button {
        width: 180px;
        height: 60px;
        background:#FFFFFF;
        color: #090110;
        font-size: 16px;
        line-height: 16px;
        position: relative;
        cursor: pointer;

        .overlay {
            position: absolute;
            width: 100%; 
            height: 100%;
            top: 0; 
            left: 0;
            background: rgba(#000000, 0.1);
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
        }

        &:hover, :active {
            .overlay {
                background: rgba(#000000, 0.3);  
            }
        }
    }
    
    .title {
        max-width: 800px;
        margin-top: 0;
        margin-bottom: 30px;
        text-align: center;
    }
}

@media (max-width: $breakpoint-desktop) {
    .container {
        .title {
            white-space: pre-wrap;
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .container {
        margin-top: 15px;

        .title {
            max-width: 320px;
        }
    
        .button {
            width: 148px;
            height: 50px;
            font-size: 14px;
            line-height: 21px;
        }     
    }
}