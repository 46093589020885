input {
    margin-bottom: 15px;
}

.checked-button::before {
    content: "";
    background: url(../../assets/ic_check_small.svg) no-repeat center;
    height: 17px;
    width: 17px;
    padding: 5px;
}