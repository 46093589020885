.reset-password-content {

    .reset-password-space {
        flex: 1;
    }

    .eye {
        path {
            stroke: rgba(255, 255, 255, 0.5);
        }

        circle {
            fill: rgba(255, 255, 255, 0.5);
        }
    }
}