.password-container {
    position: relative;
    display: flex;

    input {
        flex: 1;
    }

    .eye {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;

        &.opened {
            padding: 19px 23px 20px 20px;
        }

        &.closed {
            padding: 24px 20px 16px 20px;
        }
    }
}