@import "../../../variables";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 20px 100px;

    .title {
        text-align: center;
        margin-bottom: 50px;
    }
}

@media (max-width: $breakpoint-mobile) {
    .container {
        margin-top: 30px;
        margin-bottom: 0;

        .title {
            max-width: 320px;
        }
    }
}