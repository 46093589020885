@import "../../../variables";
@import "../../../mixins";

footer {
    width: 100%;
    background-color: #1A1321;
    
    .navigation {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        max-width: 800px;
        justify-items: center;
        justify-content: center;
        margin: 60px auto 110px;

        .title {
            width: max-content;
            color: #FFFFFF;
            font-weight: 700;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 0.3px;
        }

        ul {
            font-size: 12px;
            line-height: 30px;
            list-style: none;
            letter-spacing: 0.4px;
            padding: 0;
            margin: 5px 0 0;
            max-width: 120px;

            li {
                padding-left: 0;
                margin-bottom: 0;
                white-space: nowrap;

                a {
                    text-decoration: none;
                    color: #FFFFFF;
                    cursor: pointer;
                    @include noSelect;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        position: relative;
                    }

                    &:hover, :active {
                        opacity: 0.9;
                    }
                }
            }
        }
        .app {
            .title {
                margin-bottom: 10px;
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    footer {
        .navigation {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: flex-start;
            column-gap: 25%;
            margin: 30px 30px 31px 32px;

            .title {
                margin-bottom: 0px;
                font-size: 14px;
                line-height: 36px;
            }

            ul {
                margin: 0;
                font-size: 14px;
                line-height: 36px;

                li {
                    white-space: pre-line;
                }
            }

            .product {
                order: 2;
            }

            .socialMedia {
                order: 4;
            }
        }
    }
}