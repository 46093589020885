@import "../../../variables";
@import "../../../mixins";

.title {
    font-size: 40px;
    font-weight: 600;
    line-height: 51px;
    color: #FFFFFF;
    opacity: 0;
    @include transition(opacity, transform 1s cubic-bezier(0.5, 1, 0.89, 1) 0s);
    @include transform(translateY(50px));
    @include animation(fade-in linear 1s);

    &.animate {
      opacity: 1;
      @include transform(translateY(0));
    }

    span {
        color: #D295FF;
    }

    @include keyframes(fade-in) {
        0% { 
            opacity: 0; 
        }
        100% { 
            opacity: 1; 
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .title {
        font-size: 30px;
        line-height: 38px;
    }
}