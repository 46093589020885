@import "../../../variables";
@import "../../../mixins";

.container {
    display: flex;
    position: relative;
    max-width: 408px;
    max-height: 570px;
    color: #FFFFFF;
    overflow: hidden;
    @include transform(translateY(100px));
    @include transition(opacity .2s, transform .8s cubic-bezier(0.39, 0.575, 0.565, 1));

    &.appear {
        @include transform(translateY(0));
    }

    .background {
        width: 100%;
        max-width: 845px;
        border-radius: 18px;
    }

    img {
        max-width: 100%;
    }

    .content {
        display: flex;
        flex-direction: column;
        position: absolute;
        overflow: hidden;
        height: 100%;

        .title {
            font-size: 36px;
            line-height: 42px;
            font-weight: bold;
            margin: 35px 35px 0;
            color: #FFFFFF;
            margin-bottom: 20px;
            white-space: pre-line;
            opacity: 0;
            @include transition(opacity 1s, transform 1s cubic-bezier(0.5, 1, 0.89, 1) 0s);
            @include transform(translateY(50px));

            span {
                color: #D295FF;
            }
        }

        .description {
            font-size: 18px;
            line-height: 24px;
            margin-left: 35px;
            margin-right: 20px;
            opacity: 0;
            @include transition(opacity 1s, transform 1s cubic-bezier(0.5, 1, 0.89, 1) 0s);
            @include transform(translateY(50px));
        }

        &.animate {
            .title {
                opacity: 1;
                @include transform(translateY(0));
            }

            .description {
                opacity: 1;
                @include transform(translateY(0));
            }
        }
    }
}

@media (max-width: $breakpoint-desktop) {
    .container {
        .content {
            .title {
                font-size: 30px;
                line-height: 35px;
                margin: 20px 20px 15px 35px;
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .container {
        max-width: 335px;
        max-height: 470px;
        @include transform(translateY(50px));

        .content {
            display: flex;
            flex-direction: column;
            position: absolute;
            overflow: hidden;

            .title {
                font-size: 30px;
                line-height: 34px;
                margin: 30px 20px 15px 30px;
            }

            .description {
                font-size: 15px;
                line-height: 21px;
                margin-left: 30px;
                margin-right: 25px;
            }
        }
    }
}