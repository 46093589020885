@import "../../../../variables";

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #100A16;
  margin-top: 100px;

  &.red {
    background-color: #101010;
    
    .button {
      background-color: #FF0D38;
    }

    span {
      color: #FF0D38;
    }
  }

  .image {
    width: 100%;
    object-fit: contain;
  }

  .button {
    background-color: #45B8AF;
    background-image: none;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 70px;
    z-index: 2;

    .title {
      max-width: 960px;
      margin-bottom: 30px;
      font-weight: 300;
      font-size: 54px;
  
      .accent {
        font-weight: 600;
        color: white;
      }
    }
  
    .description {
      max-width: 480px;
      margin: 0 20px 50px;
      white-space: pre-line;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .footer {
    margin-top: 10px;

    .contentWrapper {
      margin-top: 0;

      .title {
        font-size: 32px;
        white-space: pre-line;
      }

      .description {
        margin-bottom: 30px;
      }
    }

    .image {
      width: 170%;
      object-fit: cover;
    }
  }
}