body {
    background-color: #090110;
}

.reset-password-template {
    height: 100%;
    color: rgba(#FFFFFF, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    align-self: center;

    .reset-template-logo {
        margin-top: 30px;
    }

    .reset-template-image {
        width: 100%;
        background: url(../../assets/ic_valley.svg) no-repeat;
        background-size: cover;
        flex: 1;
    }

    .reset-password-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-self: stretch;
        padding: 0 20px;
        margin-top: auto;
        flex: 2;

        .reset-password-title {
            color: white;

            .accent {
                color: #D295FF;
            }
        }

        .reset-password-description {
            margin: 15px 0;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.2px;
            color: rgba(#FFFFFF, 0.7);
        }

        input {
            background-color: rgba(#FFFFFF, 0.07);
            color: white;

            &.invalid {
                color: #FE7486;
                background-color: rgba(#FFFFFF, 0.07);
            }
        }

        .reset-password-error {
            font-size: 12px;
            line-height: 22px;
            color: #FE7486;
            margin-left: 15px;
            margin-right: 15px;
        }

        button {
            opacity: 1;

            &:disabled {
                opacity: 0.3;
                background: linear-gradient(270deg, #B250FF 0%, #802DF6 100%);
            }
        }
    }
}