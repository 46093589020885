@import "../../../../variables.scss";

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  flex: 1;
  position: relative;
  justify-content: center;
  overflow: hidden;
  background: url('../../../../assets/bidease/overlay.png') no-repeat center center;

  .logo {
    position: absolute;
    width: 80px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    left: 0;
    right: 0;
    top: 20px;
  }

  .textWrapper {
    margin: -20px auto 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    max-width: 335px;

    .image {
      background-size: contain;
      max-width: 600px;
      position: relative;
      margin-top: 20px;
      align-self: center;
      justify-self: center;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .contentContainer {
    .logo {
      width: 50px;
      height: 18px;
    }

    .textWrapper {
      background: none;
      max-width: 335px;

      .image {
        width: 140%;
        margin-top: 20px;
      }
    }
  }
}


@media (max-height: 600px) {
  .contentContainer {
    .textWrapper {
      .image {
        width: 100%;
        margin-top: 0;
      }
      margin-bottom: 0;
    }
  }
}