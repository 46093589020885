@import "../../../variables";
@import  "../../../mixins";

.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;

    .logo {
        width: 72px;
        height: 24px;
        position: absolute;
        top: 40px;
    }

    .backgroundContainer {
        width: 100%;
        position: relative;
        z-index: -1;
        overflow: hidden;

        .video {
            object-fit: contain;
            transform: scale(2) translate(0%, -10%);
            overflow: hidden;
            z-index: -1;
            max-height: 60vh;
            min-height: 50vh;
            background: transparent url('../../../assets/loonaWeb/preview.jpg') 50% 50% / contain no-repeat;
        }

        .topGradient {
            background-image: linear-gradient(to top, rgba(9, 1, 16, 0) 0%, rgba(9, 1, 16, 255) 100%);
            width: 100%;
            height: 25%;
            position: absolute;
            top: 0;
            opacity: 0.8;
            z-index: 3;
        }

        .bottomGradient {
            background-image: linear-gradient(to top, #090110 0%, rgba(9, 1, 16, 0) 100%);
            width: 100%;
            height: 20%;
            position: absolute;
            bottom: 0;
            z-index: 3;
        }
    }

    .title {
        max-width: 600px;
        margin-top: -7%;
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
    }

    .description {
        max-width: 800px;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        line-height: 27px;
        color: rgba(#FFFFFF, 0.78);
        margin: 20px 20px 15px;
        opacity: 0;
        @include transform(translateY(50px));
        @include transition(opacity, transform 1s cubic-bezier(0.5, 1, 0.89, 1) 0s);
        @include animation(fade-in linear 1s);

        &.animate {
            opacity: 1;
            @include transform(translateY(0));
        }
    }

    .button {
        width: 170px;
        height: 60px;
        border-radius: 33px;
        font-size: 16px;
        line-height: 16px;
        position: relative;
        cursor: pointer;

        .overlay {
            position: absolute;
            width: 100%; 
            height: 100%;
            top: 0; 
            left: 0;
            background: rgba(#000000, 0.1);
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
        }

        &:hover, :active {
            .overlay {
                background: rgba(#000000, 0.3);
            }
        }
    }

    .playerWrapper {
        width: 100%;
        overflow: hidden;
        z-index: -1;

        .video {
            object-fit: none;
            height: 717px;
        }
    }

    @include keyframes(fade-in) {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

}

@media (max-width: $breakpoint-mobile) {
    .header {
        margin-bottom: 0;

        .logo {
            width: 70px;
            height: 24px;
            top: 20px;
        }

        .backgroundContainer {
            .video {
                min-height: 50vh;
                max-height: 60vh;
                transform: scale(4);
            }
        }

        .title {
            max-width: 318px;
            text-align: center;
        }

        .description {
            max-width: 320px;
            text-align: center;
            font-weight: 400;
            font-size: 15px;
            line-height: 21px;
            margin-top: 15px;
            margin-bottom: 30px;
        }

        .button {
            width: 148px;
            height: 50px;
            border-radius: 68px;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            margin: 0;
        }
    }
}


@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-desktop) {
    .header {
        .backgroundContainer {
            .video {
                min-height: 50vh;
                max-height: 60vh;
                transform: scale(3) translate(0%, -2%);
            }
        }
    }
}