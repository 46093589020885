#onetrust-pc-sdk {
  box-sizing: border-box;
  
  .ot-pc-header {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: 0 20px;

    #close-pc-btn-handler {
      background-size: 16px;

      &:focus {
        outline: 1px solid #FFFFFF !important; 
      }

      &.ot-close-icon {
        background-image: url('./assets/close.svg');
      }
    }

    .ot-pc-logo {
      width: 70px;
      height: 24px;
      background-image: url('./assets/logo_loona_purple.svg') !important;
      margin-top: 6px !important;
    }
  }
  
  .ot-sdk-row {
    #ot-category-title {
      display: none !important;
    }

    .ot-cat-item {
      width: calc(100% - 40px) !important;
      background-color: rgba(9, 1, 16, 0.08) !important;
      border-radius: 16px !important;
      margin: 0 20px 10px !important;
      border: none !important;
      box-sizing: content-box !important;
      padding: 24px 15px !important;

      &.ot-vs-config {
        box-sizing: border-box !important;
        margin-right: 0px !important;
      }
  
      button {
        width: 100% !important;
        padding: 0 !important;
        outline-color: transparent !important;
        border-radius: 16px !important;
      }

      .ot-arw-cntr {
        flex: auto;
        
        .ot-arw {
          width: 18px;
          height: 18px;
          margin-top: -5px;
          margin-left: -15px !important;
          color: #090110;
        }
      }

      .ot-acc-hdr {
        height: 32px;

        .ot-tgl {
          input {
            &:checked+.ot-switch {
              .ot-switch-nob {
                background: linear-gradient(270deg, #B250FF 0%, #802DF6 100%) !important;
                box-shadow: 0 0 1px #B250FF !important;
                outline-style: none !important;

                &::before {
                  width: 24px !important;
                  height: 24px !important;
                  right: 20px !important;
                  background-color: #FFFFFF !important;
                  outline-width: 0px !important;
                }
              }
            }
          }

          .ot-switch {
            width: 56px !important;
            height: 32px !important;

            .ot-switch-nob {
              background: rgba(#000000, 0.25) !important;
              box-shadow: 0 0 1px rgba(#000000, 0.25) !important;
              outline-style: none !important;
              border: none !important;

              &::before {
                width: 24px !important;
                height: 24px !important;
                background-color: #FFFFFF !important;
                outline-width: 0px !important;
                bottom: 1px !important;
                left: 3px !important;
                top: 1px !important;
                margin: auto !important;
              }
            }
          }
        }
      }

      button  ~ .ot-acc-hdr {
        :nth-child(1) { 
          order: 1; 
        }

        :nth-child(2) { 
          order: 3; 
        }

        :nth-child(3) { 
          order: 2; 
        }
      
        .ot-cat-header {
          max-width: max-content;
          flex-basis: auto;
          color: #090110 !important;
        }
      }

      button[aria-expanded=true] ~ .ot-acc-hdr {
        .ot-arw-cntr {
          .ot-arw {
            transform: rotate(270deg) !important;
          }
        }
      }

      button[aria-expanded=false] ~ .ot-acc-hdr {
        padding: 0 !important;

        .ot-arw-cntr {
          .ot-arw {
            transform: rotate(90deg) !important;
          }
        }
      }
    }
  }
  
  .ot-acc-txt {
    background-color: rgba(9, 1, 16, 0) !important;

    .ot-category-desc {
      font-size: 14px;
      line-height: 20px;
      padding: 20px 10px !important;
      color: #090110 !important;
      font-size: 14px !important;
      font-weight: 500;
    }
  }
  
  #ot-pc-content {
    width: 100% !important;
    height: auto;
    position: relative !important;
    margin: 0 !important;
    overflow: auto;
    padding: 0 !important;
    top: 0px !important;
    overflow-x: hidden;

    #ot-pc-title {
      font-size: 20px;
      margin: 20px 20px 12px !important;
      line-height: 32px;
      font-weight: 700;
      color: #090110 !important;
    }

    #ot-pc-desc {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: 0.2px;
      color: #090110 !important;
      margin: 0px 20px 20px !important;
    }

    #accept-recommended-btn-handler {
      display: none !important;
    }
  }
  
  .ot-always-active-group {
    .ot-cat-header {
      color: #090110 !important;
    }

    .ot-always-active {
      display: none;
    }
  }
  
  .ot-pc-footer {
    width: 100%;
    border-top: none !important;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;

    .ot-btn-container {      
      .ot-pc-refuse-all-handler {
        height: 48px;
        border-radius: 30px;
        color: #FFFFFF !important;
        background-color: #7F2EF2 !important;
        border: none !important;
        font-size: 14px !important;
        font-weight: 600;

        &:hover {
          opacity: 1 !important;
        }

        &:focus {
          opacity: 1 !important;
          border-color: none !important;
        }
      }

      .save-preference-btn-handler {
        width: 100%;
        height: 48px;
        border-radius: 30px;
        background-color: #FFFFFF !important;
        border-color: #802DF6 !important;
        color: #802DF6 !important;
        font-size: 14px !important;
        font-weight: 600;
        margin-top: 10px !important;

        &:hover {
          opacity: 1;
        }
      }
    }

    .ot-pc-footer-logo {
      margin-top: 5px !important;
      display: none !important;
    }
  }
}