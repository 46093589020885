@import "../../../variables";
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    align-items: center;

    .description {
        color: rgba(#FFFFFF, 0.78);

        .accent {
            font-weight: 700;
            color: rgba(#FFFFFF, 1);
        }
    }

    .topGradient {
        width: 100%;
        height: 20%;
        top: 0;
        position: absolute;
        z-index: 1;
        transform: matrix(1, 0, 0, -1, 0, 0);
        background: linear-gradient(
            180deg, 
            rgba(#090110, 0) 0%, 
            rgba(#090110, 0.0086472) 6.67%, 
            rgba(#090110, 0.03551) 13.33%, 
            rgba(#090110, 0.0816599) 20%, 
            rgba(#090110, 0.147411) 26.67%, 
            rgba(#090110, 0.231775) 33.33%, 
            rgba(#090110, 0.331884) 40%, 
            rgba(#090110, 0.442691) 46.67%, 
            rgba(#090110, 0.557309) 53.33%, 
            rgba(#090110, 0.668116) 60%, 
            rgba(#090110, 0.768225) 66.67%, 
            rgba(#090110, 0.852589) 73.33%, 
            rgba(#090110, 0.91834) 80%, 
            rgba(#090110, 0.96449) 86.67%, 
            rgba(#090110, 0.991353) 93.33%, 
            #090110 100%
        );
    }

    .background {
        background: url('../../../assets/loonaWeb/techniques_bg.png') center no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
    }

    .button {
        width: 240px;
        height: 60px;
        background: rgba(255, 255, 255, 0.15);
        z-index: 3;
        position: relative;
        cursor: pointer;

        .overlay {
            position: absolute;
            width: 100%; 
            height: 100%;
            top: 0; 
            left: 0;
            background: rgba(#000000, 0.1);
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
            border-radius: 35px;
        }

        &:hover, :active {
            .overlay {
                background: rgba(#000000, 0.25);
            }
        }

        a {
            color: #FFFFFF;
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;
            text-decoration: none;
        }

        svg {
            margin-left: 8px;
        }
    }

    .title {
        max-width: 600px;
        margin-top: 150px;
        margin-bottom: 25px;
        text-align: center;
        z-index: 2;
    }

    .cardsWrapper {
        display: grid;
        grid-gap: 30px;
        margin: 50px 20px 130px;
        justify-content: center;

        .bundles {
            transition-delay: 0.2s;
            .bundlesTitle {
                font-size: 30px;
                line-height: 36px;
                color: black;
                letter-spacing: -0.8px;
            }

            .subtitle {
                max-width: 280px;
                color: black; 
                font-size: 14px;
                line-height: 18px; 
                margin-left: 35px;
                z-index: 2;
            }

            .sign {
                max-width: 45%;
                height: 63px;
                margin: 15px 26px;
                object-fit: contain;
            }

            .doctor {
                max-width: 50%;
                max-height: 40%;
                position: absolute;
                bottom: 0;
                right: 0;
                border-bottom-right-radius: 18px;
                object-fit: cover;
            }
        }
    
        .artActivities {
            transition-delay: 0.4s;
            .progress {
                object-fit: contain;
                margin: 30px 40px 30px;
            }

            .phone {
                flex: 1 0 auto;
                max-width: 100%;
            }
        }
    
        .tree {
            max-width: 1120px;
            grid-column: 1 / 3;
            grid-row: 2 / 4;
            transition-delay: 0.2s;
    
            .treeTitle {
                font-size: 54px;
                line-height: 54px;
                white-space: pre-wrap;
                margin-top: 10px;
            }
        }
    
        .breathingPractices {
            transition-delay: 0.2s;

            .phone {
                position: absolute;
                bottom: 0;
                max-width: 85%;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    
        .soundHealing {
            transition-delay: 0.4s;
            color: black;
    
            .soundHealingTitle {
                color: black;
            }
        }
    }
}

@media (max-width: $breakpoint-desktop) {
    .container {
        .cardsWrapper {
            grid-gap: 20px;

            .bundles {
                .bundlesTitle {
                    font-size: 27px;
                    line-height: 30px;
                    margin-left: 0;
                }

                .subtitle {
                    max-width: 190px;
                }
            }
            
            .tree {
                .treeTitle {
                    font-size: 49px;
                    line-height: 53px;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .container {
        margin-bottom: 20px;

        .background {
            background: url('../../../assets/loonaWeb/mobile/techniques_bg.png') center no-repeat;
            background-size: cover;
            height: 100%;
        }

        .topGradient {
            height: 15%;
            top: 0;
            z-index: 0;
        }

        .background {
            background: url('../../../assets/loonaWeb/mobile/techniques_bg.png') center no-repeat;
            background-size: cover;
            height: 100%;
        }

        .bottomGradient {
            width: 100%;
            height: 5%;
            bottom: 0;
            position: absolute;
            z-index: -1;
            background: linear-gradient(
                180deg, 
                rgba(#090110, 0) 0%, 
                rgba(#090110, 0.0086472) 6.67%, 
                rgba(#090110, 0.03551) 13.33%, 
                rgba(#090110, 0.0816599) 20%, 
                rgba(#090110, 0.147411) 26.67%, 
                rgba(#090110, 0.231775) 33.33%, 
                rgba(#090110, 0.331884) 40%, 
                rgba(#090110, 0.442691) 46.67%, 
                rgba(#090110, 0.557309) 53.33%, 
                rgba(#090110, 0.668116) 60%, 
                rgba(#090110, 0.768225) 66.67%, 
                rgba(#090110, 0.852589) 73.33%, 
                rgba(#090110, 0.91834) 80%, 
                rgba(#090110, 0.96449) 86.67%, 
                rgba(#090110, 0.991353) 93.33%, 
                #090110 100%
            );
        }

        .title {
            white-space: pre-line;
            margin: 100px 20px 20px;
        }

        .button {
            width: 224px;
            height: 50px;
            font-size: 14px;
        }

        .cardsWrapper {
            grid-template-columns: 1fr;
            margin-top: 44px;
            margin-bottom: 50px;
            gap: 20px;

            .bundles {
                .bundlesTitle {
                    font-size: 25px;
                    line-height: 30px;
                    color: black;
                }

                .subtitle {
                    font-size: 12px;
                    line-height: 15px; 
                    max-width: 170px;
                    margin-left: 30px;
                }
                
                .sign {
                    margin: 10px 25px;
                }
            }

            .breathingPractices {
                .title {
                    max-width: 140px;
                }
            }
    
            .tree {
                max-width: 335px;
                height: 468px;
                grid-column: 1;
                grid-row: 3;
        
                .treeTitle {
                    font-size: 30px;
                    line-height: 35px;
                }
            }

            .artActivities {
                .progress {
                    margin: 20px 30px 20px;
                }
            }
        }
    }
}
