@import "../../../../../variables";

.card {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    padding: 0 20px;

    .content {
        display: flex;
        align-items: center;
        position: relative;

        .background {
            width: 100%;
            height: 100%;
            max-width: 840px;
            max-height: 580px;
            border-radius: 20px;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
        }

        .text {
            position: absolute;
            max-width: 346px;
            font-size: 18px;
            line-height: 27px;
            color: rgba(#FFFFFF, 0.78);
            margin-left: 45px;
            margin-right: 40px;
        }
    }
}

@media (max-width: $breakpoint-desktop) {
    .card {
        .content {
            .text {
                max-width: 300px;
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .card {
        .background {
            max-width: 335px;
            min-height: 590px;
        }

        .content {
            .text {
                top: 0;
                max-width: 290px;
                font-size: 15px;
                line-height: 21px;
                margin: 30px 20px 0 30px;
            }
        }
    }
}