@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin animation($animation) {
    animation: #{$animation};
    -webkit-animation: #{$animation};
    -moz-animation: #{$animation};
    -o-animation: #{$animation};
    -ms-animation: #{$animation};
}
  
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
      @content;
    }
    @-moz-keyframes #{$name} {
      @content;
    }
    @-ms-keyframes #{$name} {
      @content;
    }
    @-o-keyframes #{$name} {
      @content;
    }
    @keyframes #{$name} {
      @content;
    }
}

@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

@mixin noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}