@import "../../../variables";

.container {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    flex: 1 0 auto;

    .pillow {
        width: 100%;
        max-width: 1440px;
        min-width: 1050px;
    }

    .textWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        text-align: center;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);

        .title {
            font-weight: 600;
            font-size: 30px;
            line-height: 38px;
            color: #FFFFFF;
    
            span {
                color: #D295FF;
            }
        }

        .description {
            font-weight: 400;
            font-size: 18px;
            line-height: 33px;
            letter-spacing: 0.4px;
            color: rgba(#FFFFFF, 0.78);
            text-align: center;
        }

        .button {
            width: 170px;
            height: 60px;
            background: #FFFFFF;
            color: rgba(9, 1, 16, 1);
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            position: relative;
            cursor: pointer;

            .overlay {
                position: absolute;
                width: 100%; 
                height: 100%;
                top: 0; 
                left: 0;
                background: rgba(#000000, 0.1);
                transition: all 0.5s;
                -webkit-transition: all 0.5s;
                border-radius: 35px;
            }
    
            &:hover, :active {
                .overlay {
                    background: rgba(#000000, 0.25);
                }
            }
        }
    }

    .grass {
        width: 100%;
        position: absolute;
        bottom: -9%;
        z-index: -1;
    }
}

@media (max-width: $breakpoint-desktop) {
    .container {
        .textWrapper {
            top: 73%;

            .title {
                font-size: 28px;
            }

            .button {
                width: 149px;
                height: 50px;
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .container {
        .pillow {
            margin-top: 0;
            margin-bottom: 50px;
            min-width: unset;
        }

        .textWrapper {
            width: 80%;
            top: 45%;

            .title {
                max-width: 178px;
                font-size: 30px;
                line-height: 38px;
                text-align: center;
                white-space: pre-line;
                margin-bottom: 10px; 
            }

            .description {
                font-size: 15px;
                line-height: 21px;
                margin-bottom: 10px;
            }

            .button {
                width: 149px;
                height: 50px;
                font-size: 14px;
                line-height: 21px;
            }
        }
        .grass {
            bottom: 0;
        }
    }
}