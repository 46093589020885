button {
  background-image: linear-gradient(to right, #802DF6 0%, #B250FF  100%);
  border: none;
  border-radius: 50px;
  color: white;
  margin: 10px 0;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
  outline: none;
  max-width: 500px;
  width: 100%;
  height: 52px;
  font-family: 'Euclid Circular A';
    
  &.inverted {
    background-image: none;
    background-color: transparent;
    color: #B250FF;
    border: 1px solid #D5ADF9;
  }

  &:disabled {
    background-image: none;
    background-color: #E6E6E7;
  }
}

h1 {
  font-family: Euclid Circular A;
  font-style: normal;
  font-weight: bold;
  font-size: 29px;
  line-height: 32px;

  span {
    &.accent {
      color: #d295ff;
    }
  }
}

input[type=email], input[type=password], input[type=text] {
  margin: 5px 0;
  font-family: Euclid Circular A;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  height: 52px;
  background-color: rgba(9, 1, 16, 0.07);
  border: none;
  border-radius: 40px;
  padding: 0 20px;
  caret-color: #802DF6;
  transition: .2s;
  color: rgba(9, 1, 16, 0.5);
  box-sizing: border-box;
  width: 100%;

  &:focus {
    outline: none;
  }

  &.invalid {
    color: #E34646;
    background-color: rgba(227, 70, 70, 0.1);
  }
}