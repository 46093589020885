@import "../../../../variables";

.button {
  max-width: 247px;
  height: 72px;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  a {
    text-decoration: none;
    color: #FFFFFF;
  }
}

@media (max-width: $breakpoint-mobile) {
  .button {
    width: 192px;
    height: 52px;
    font-size: 18px;
    line-height: 40px;
  }
}