@import "../../../../variables.scss";

.title {
  font-weight: bold;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: -0.2px;
  margin: 30px 0 5px;
  color: white;
  text-align: center;
}

.subtitle {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #FFFFFF;
}

.description {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.6);
  margin: 15px 0 10px;
  text-align: center;
  white-space: pre-line;
}

.contentContainer {
  width: 100%;
  padding: 0;
  justify-content: center;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  background: url('../../../../assets/bidease/overlay.png') no-repeat center center;

  .flex {
    flex: 1;
  }

  .logo {
    position: absolute;
    width: 80px;
    height: 30px;
    top: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .textWrapper {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 335px;
    margin: 50px auto 10px;

    .image {
      max-width: 100%;
      margin-bottom: 30px;
      align-self: center;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .contentContainer {
    margin-top: 0;
    margin-bottom: 0;
    justify-content: flex-start;

    .logo {
      width: 50px;
      height: 18px;
    }

    .textWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: center;
      flex: 2;
      z-index: 2;
      margin: 0 auto;

      .image {
        max-width: 80%;
        margin-bottom: 0;
      }
    }
  }
}