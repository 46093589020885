@font-face {
  font-family: 'Euclid Circular A';
  font-weight: 400;
  src: url("./fonts/EuclidCircularA_regular.otf") format("opentype");
}

@font-face {
  font-family: 'Euclid Circular A';
  font-weight: 500;
  src: url("./fonts/EuclidCircularA_medium.otf") format("opentype");
}

@font-face {
  font-family: 'Euclid Circular A';
  font-weight: 600;
  src: url("./fonts/EuclidCircularA_semibold.otf") format("opentype");
}

@font-face {
  font-family: 'Euclid Circular A';
  font-weight: 700;
  src: url("./fonts/EuclidCircularA_bold.otf") format("opentype");
}

@font-face {
  font-family: 'Dancing Script';
  font-weight: 700;
  src: url("./fonts/DancingScript-Bold.ttf") format("opentype");
}

@font-face {
  font-family: 'SanFrancisco';
  src: url('./fonts/SFProDisplay-Heavy.eot');
  src: url('./fonts/SFProDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
  url('./fonts/SFProDisplay-Heavy.woff') format('woff'),
  url('./fonts/SFProDisplay-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SanFrancisco';
  src: url('./fonts/SFProDisplay-Bold.eot');
  src: url('./fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/SFProDisplay-Bold.woff') format('woff'),
  url('./fonts/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SanFranciscoRegular';
  src: url('./fonts/SFProDisplay-Regular.eot');
  src: url('./fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/SFProDisplay-Regular.woff') format('woff'),
  url('./fonts/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SanFranciscoBlack';
  src: url('./fonts/SFProDisplay-Black.eot');
  src: url('./fonts/SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
  url('./fonts/SFProDisplay-Black.woff') format('woff'),
  url('./fonts/SFProDisplay-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Euclid Circular A', sans-serif;
  font-weight: 400;
  background-color: #090110;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  letter-spacing: 0.2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}