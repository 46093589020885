.bodyBackground {
  background-color: white;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .logo {
    width: 100px;
    height: 40px;
    margin: 44px auto 0 auto;
  }

  .article {
    padding: 0 0 150px 0;
    margin: 0 40px;
    max-width: 840px;
    font-family: 'SanFranciscoRegular', sans-serif;
    font-size: 20px;
    line-height: 1.2em;

    h1 {
      padding: 50px 0 0 0;
      font-family: 'SanFrancisco', sans-serif;
      font-weight: 900;
      font-size: 50px;
      line-height: 1.2em;
    }

    h2 {
      padding: 50px 0 0 0;
      font-family: 'SanFrancisco', sans-serif;
      font-weight: 900;
      font-size: 28px;
      line-height: 1.1em;
    }

    p {
      padding: 10px 0;
      margin: 0;
    }

    ol ul {
      list-style-type: disc;
    }

    ol, ul {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 0;
    }

    li {
      margin-top: 5px;
      margin-left: 40px;

      &::marker {
        font-weight: bold;
      }
    }

    i {
      font-style: italic;
    }

    a {
      text-decoration: underline;
      color: #9833E4;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .container {
    .logo {
      width: 200px;
      height: 28px;
      margin: 30px auto 30px auto;
      top: 24px;
    }

    .article {
      margin: 0 20px;

      h1 {
        padding: 20px 0 0 0;
        font-family: 'SanFrancisco', sans-serif;
        font-weight: 900;
        font-size: 25px;
        line-height: 1.2em;
      }

      h2 {
        font-size: 20px;
        padding-top: 10px;
      }

      h3 {
        padding-bottom: 12px;
        font-weight: 900;
        font-size: 18px;
      }

      p, li {
        font-size: 16px;
        line-height: 1.25em;
      }
    }
  }
}