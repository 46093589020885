@import "../../../../../variables";
@import "../../../../../mixins";

.centeredDot {
    :global {
        .react-multi-carousel-dot-list {
            scroll-snap-type: x mandatory;
            -webkit-scroll-snap-type: x mandatory;
        }
    }
}

:global {
    .react-multi-carousel-list {
        width: 100%;
    
        .react-multi-carousel-track  {
            margin-top: 120px;
        }
    
        .accent {
            font-weight: 700;
            color: rgba(#FFFFFF, 1);
        }
    
        .react-multi-carousel-dot-list {
            display: flex;
            max-width: 950px;
            max-height: 100px;
            top: 20px;
            white-space: nowrap;
            margin-right: auto;
            margin-left: auto;
            overflow-x: auto;
            justify-content: flex-start;
    
    
            &::-webkit-scrollbar {
                display: none;
            }
    
            li {
                display: flex;
                align-items: center;
            }
    
            .react-multi-carousel-item {
                color: #FFFFFF;
                opacity: 0.4;
                font-size: 24px;
                line-height: 36px;
                font-family: 'Euclid Circular A';
                font-weight: 600;
                margin-left: 5px;
                margin-right: 20px;
                cursor: pointer;
                @include noSelect;

                &:first-child {
                    margin-left: 20px;
                }

                &:last-child {
                    margin-right: 20px;
                }
    
                &.active {
                    color: #B250FF;
                    opacity: 1;
                }
            }
        }
    }
    
    @media (max-width: $breakpoint-tablet) {
        .react-multi-carousel-list {
            .react-multi-carousel-track  {
                margin-top: 75px;
            }
    
            .react-multi-carousel-dot-list {
                max-height: 50px;
                top: 10px;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: -ms-autohiding-scrollbar;
    
                .react-multi-carousel-item {
                    font-size: 16px;
                    line-height: 48px;
                }
            }
        }
    }

    @media (max-width: $breakpoint-desktop) {
        .react-multi-carousel-item.active {
            scroll-snap-align: center;
            -webkit-scroll-snap-align: center;
        }
    }
}