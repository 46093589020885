@import "../../../../../variables";

.advantageItem {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.red {
    svg {
      path {
        fill: #FF0D38;
      }
    }
  }

  .textWrapper {
    max-width: 490px;
    margin-top: 40px;

    .title {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 20px;
    }
  
    .description {
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: rgba(#FFFFFF, 0.78);
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .advantageItem {
    margin-top: 50px;

    img {
      width: 90px;
    }

    .textWrapper {
      max-width: 320px;

      .title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 12px;
      }
  
      .description {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

