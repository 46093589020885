.switch-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  color: #090110;

  > div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
  }
}