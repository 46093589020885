.container {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  min-height: 79px;
  background: #f3f3f3;
  font-family: Helvetica, sans, sans-serif;
  z-index: 1000;
  border-bottom: 1px solid #BDBDC2;

  &.hidden {
    display: none;
  }

  .starsIcon {
    min-height: 16px;
  }

  .closeIcon {
    position: absolute;
    width: 30px;
    padding: 25px 0;
    left: 0;
    top: -2px;
    font-size: 20px;
    bottom: 0;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    color: #BBBBC0;
    cursor: pointer;
  }

  .bannerIcon {
    position: absolute;
    top: 7px;
    left: 30px;
    width: 64px;
    height: 64px;
    border-radius: 15px;
    background-size: 64px 64px;
  }

      
  .content {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 8px;
    left: 104px;
    display: flex;
    overflow-y: hidden;
    color: #000; 

    .title {
      font-size: 14px; 
      line-height: 16px;
    }

    .subtitle {
      font-size: 12px;
      line-height: 14px;
      color: #848489;
    }

    .author {
      font-size: 12px;
      color: #848489;
      line-height: 16px;
    }
  }

  .button {
    display: flex;
    justify-content: end;
    align-items: center;
    max-width: 50px;
    position: absolute;
    right: 10px;
    padding: 0 10px;
    min-width: 10%;
    border-radius: 5px;
    background: none;
    color: #1474fc;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    text-decoration: none; 
    z-index: 0;
  }
}