@import "../../../../variables";

h1, h2 {
  margin: 0;
}

.title {
  font-size: 54px;
  font-weight: 600;
  line-height: 62px;
  text-align: center;
}

@media (max-width: $breakpoint-mobile) {
  .title {
    font-size: 30px;
    line-height: 40px;
  }
}