@import "../../../variables";

.container {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    box-sizing: border-box;
    justify-items: center;
    border-radius: 24px;

    .description {
        color: rgba(#FFFFFF, 0.78);

        .accent {
            font-weight: 700;
            color: rgba(#FFFFFF, 1);
        }
    }

    .anxiety {
        transition-delay: 0.2s;
        .breadCrumbs {
            position: absolute;
            width: 90%;
            right: 0;
            bottom: 35px;
            object-fit: contain;
        }
    }

    .sleep {
        transition-delay: 0.4s;

        .title {
            .accent {
                color: #91C1F2;
            }
        }

        .description {
            margin-bottom: 40px;
        }
        
        .phone {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            max-width: 87%;
        }
    }

    .wakeUp {
        transition-delay: 0.2s;

        .title {
            white-space: nowrap;
            .accent {
                color: #FFD0C3;
            }
        }
        .phone {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            max-width: 87%;
        }
    }

    .perform {
        transition-delay: 0.4s;

        .progress {
            flex: 1 0 auto;
            object-fit: scale-down;
            margin-top: 15px;
        }
    }
}

@media (max-width: $breakpoint-desktop) {
    .container {
        gap: 20px;
        margin-left: 20px;
        margin-right: 20px;

        .anxiety {
            .anxietyTitle {
                font-size: 33px;
                line-height: 36px;
            }
        }

        .perform {
            .progress {
                bottom: -20px;
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .container {
        grid-template-columns: 1fr;
        margin-top: 44px;
        margin-bottom: 50px;
        gap: 20px;

        .perform {
            .progress {
                margin-top: 10px;
            }
        }

        .sleep {
            .phone {
                max-width: 90%;
            }
        }
    }
}