.loader-dot {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background-color: white;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    &:nth-child(2) {
        animation-delay: .2s;
    }

    &:nth-child(3) {
        animation-delay: .4s;
    }

    &:not(:last-child) {
        margin-right: 5px;
    }
}

@keyframes blink {
    0% {
      opacity: .2;
    }

    20% {
      opacity: 1;
    }
    
    100% {
      opacity: .2;
    }
}