@import "../../../../variables";

.advantagesContainer {
  display: grid;
  flex-direction: column;
  max-width: 1100px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  margin: 130px 40px 0;
}

@media (max-width: $breakpoint-desktop) {
  .advantagesContainer {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    margin: 50px 20px;
  }
}