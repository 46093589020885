.socialLoginButton {
  border: 1px solid rgba(255, 255, 255, 0.15);
  background-image: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;

  img,
  svg {
    padding-right: 10px;
  }
}
