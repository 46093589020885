@import "../variables.scss";

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: url('../assets/vr/background.png') no-repeat top 110% center;
  background-size: cover;

  .backgroundGradient {
    position: absolute;
    bottom: 0;
    z-index: 1;
    height: 150%;
    width: 100vw;
    background: linear-gradient(#09011000 0%, #090110 70%, #090110 100%);
  }

  .mobileBackgroundGradient {
    display: none;
    position: absolute;
    top: 0;
    z-index: 1;
    height: 15%;
    width: 100vw;
    background: linear-gradient(#09011088 0%, #09011000 100%);
  }

  .textContainer {
    z-index: 1;
  }

  span {
    font-family: 'Euclid Circular A';
    font-style: normal;
    text-align: center;
  }

  .form {
    position: relative;
    padding: 0 30px 80px 30px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    color: white;

    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 51px;

      &.highlighted {
        color: #D295FF;
      }
    }

    .description {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      display: flex;
      max-width: 600px;
      margin-top: 20px;
    }

    .anounce {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      display: flex;
      margin-top: 20px;
    }
  }

  .logo {
    position: absolute;
    width: 72px;
    margin-top: 38px;
    z-index: 1;
  }
}

.flex {
  display: flex;
}

@media (max-width: $breakpoint-mobile) {
  .container {
    background: url('../assets/vr/background.png') no-repeat top center;
    background-size: auto 70%;

    .logo {
      width: 70px;
      margin-top: 22px;
    }

    .mobileBackgroundGradient {
      display: block;
    }

    .backgroundGradient {
      height: 100vh;
      background: linear-gradient(#09011000 50%, #090110 70%, #090110 100%);
    }

    .form {
      padding: 0 30px 40px 30px;

      .title {
        font-size: 30px;
        line-height: 38px;
      }
  
      .description {
        font-size: 15px;
        line-height: 21px;
      }
  
      .anounce {
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
}