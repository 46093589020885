@import "../../../../variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.red {
    span {
      color: #FF0D38;
    }
  }

  .title {
    max-width: 630px;
    margin-bottom: 0;
    font-weight: 300;
    white-space: pre-line;

    .accent {
      font-weight: 600;
      color: white;
    }
  }

  .description {
    max-width: 780px;
    margin-top: -100px;
    font-size: 32px;
    line-height: 44px;

    .accent {
      font-weight: 600;
      color: white;
    }
  }

  .cardsBackground {
    width: 100%;
    margin-top: -70px;
    background-size: cover;
  }
}

@media (max-width: 1200px) {
  .container {
    .description {
      margin-top: -50px;
    }
  }
}

@media (max-width: $breakpoint-desktop) {
  .container {
    .cardsBackground {
      margin-top: -40px;
    }

    .description {
      margin: -20px 20px 0;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .container {
    .cardsBackground {
      margin-top: 20px;
      transform: scale(1.2);
    }

    .title {
      font-size: 32px;
      line-height: 35px;
      max-width: 320px;
    }

    .description {
      max-width: 320px;
      margin-top: 10px;
      font-size: 18px;
      line-height: 23px;
    }
  }
}