.bodyBackground {
  background-color: white;
}
  
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex: 1 0 auto;

  .logo {
    width: 72px;
    height: 24px;
    margin: 37px auto 0;
  }

  .article {
    padding: 0 0 90px 0;
    margin: 0 40px;
    max-width: 840px;
    font-family: 'Euclid Circular A', sans-serif;
    font-size: 20px;
    line-height: 1.2em;
    overflow-x: hidden;

    h1 {
      padding: 60px 0 20px;
      text-align: center;
      font-weight: 600;
      font-size: 40px;
      line-height: 1.2em;
    }

    h2 {
      padding: 50px 0 10px 0;
      font-weight: 600;
      font-size: 26px;
      line-height: 1.1em;
    }

    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.1em;
      margin-top: 20px;
      margin-bottom: 0;
    }

    .note {
      font-size: 14px;
      text-align: center;
      font-weight: 600;

      .light {
        color: rgba(9, 1, 16, 0.6);
      }
    }

    .description {
      max-width: 700px;
      letter-spacing: 0;
      margin: 0 auto;
      text-align: center;
    }

    p {
      font-size: 18px;
      padding: 10px 0;
      margin: 0;
      white-space: pre-line;

      &.bold {
        font-weight: 500;
      }

      &.bottom0 {
        padding-bottom: 0;
      }
    }

    ul, ol {
      padding-inline-start: 25px;
      margin-block-start: 0;
      margin-block-end: 0;
      
      li {
        font-size: 18px;
        margin-top: 10px;
        line-height: 1.5em;

        .bold {
          font-weight: 600;
        }
      }
    }

    a {
      text-decoration: none;
      color: #802DF6;;
      font-weight: 500;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
  
@media screen and (max-width: 960px) {
  .container {
    .logo {
      width: 70px;
      height: 24px;
      margin: 20px auto;
    }

    .article {
      width: calc(100% - 40px);
      overflow-x: hidden;
      padding: 0 0 50px 0;

      .tableWrapper {
        overflow: auto;

        &::-webkit-scrollbar {
          height: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #D295FF;
          border-radius: 3px;
        }
      }

      h1 {
        padding: 20px 0 0 0;
        font-weight: 900;
        font-size: 32px;
        line-height: 1.2em;
      }

      h2 {
        font-size: 20px;
        padding-top: 40px;
      }

      h3 {
        font-weight: 900;
        font-size: 15px;
      }

      .note {
        margin-top: 37px;
      }

      p {
        font-size: 15px;
        line-height: 1.25em;
      }

      ul, ol {
        li {
          font-size: 15px;
          line-height: 1.25em;
        }
      }
    }
  }
}