@import "../../../../variables";

.description {
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
}

@media (max-width: $breakpoint-mobile) {
  .description {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }
}