@import "../../../variables";

.title {
  color: white;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.4px;
  margin: 20px 20px 18px;
  max-width: 500px;

  .accent {
    color: #d295ff;
  }
}

input {
  background: rgba(#FFFFFF, 0.07);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #ffffff;

  &::placeholder {
    font-weight: normal;
  }
}

.submitButton {
  &:disabled {
    background: linear-gradient(270deg, rgba(178, 80, 255, 0.3) 0%, rgba(128, 45, 246, 0.3) 100%);
    color: rgba(#FFFFFF, 0.3);
  }
}

.helpSection {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.4px;
  color: rgba(#FFFFFF, 0.8);

  a {
    font-weight: 500;
    color: rgba(#FFFFFF, 0.8);
  }
}

@media (max-width: $breakpoint-mobile) {
  .title {
    font-size: 26px;
    line-height: 36px;
    margin-top: 0;
    margin-bottom: 12px;
  }
}
