@import "../../../../variables";
@import "../../../../mixins";

.item {
    max-width: 840px;
    background: #1A1321;
    border-radius: 24px;
    margin-bottom: 20px;
    cursor: pointer;
    @include noSelect;

    .questionContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 25px 25px 25px 40px;
            
        .question {
            color: #FFFFFF;
            text-align: left;
            font-size: 18px;
            font-weight: 600;
            line-height: 23px;
            letter-spacing: 0.23px;
        } 

        svg {
           &:hover, :active {
               opacity: 0.7;
           }
        }
    }

    .answer {
        color: #FFFFFF;
        transition: max-height 0.6s ease;
        text-align: left;
        overflow: hidden;
        margin-right: 30px;
        margin-left: 45px;

        a {
            text-decoration-color: #FFFFFF;
            color: #FFFFFF;
        }

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 23px;
            margin-bottom: 60px;
        }

    }
}

@media (max-width: $breakpoint-mobile) {
    .item {
        border-radius: 16px;
        margin-bottom: 10px;

        .questionContent {  
            margin: 25px 20px 25px 27px;
            .question {
                font-size: 14px;
                line-height: 18px;
            }

            svg {
                width: 30px;
                height: 30px;
            }
        }
        .answer {   
            margin-right: 11px;
            margin-left: 27px;   
            p {
                max-width: 278px;
                font-size: 15px;
                line-height: 21px;
                margin: 20px 20px 40px 0;
            }
        }
    }
}