.table {
    width: 842px;
    font-size: 14px;
    border-spacing: 0;

    td, th {
        min-width: 70px;
    }

    th {
        padding: 30px 32px 13px;
        text-align: left;
        color: rgba(#090110, 0.5);
    }

    td {
        padding: 20px 32px 20px;
        white-space: pre-line;
        vertical-align: top;

        .label {
            padding: 5px 14px;
            border-radius: 51px;
            font-weight: 600;

            &.green {
                background-color: #CCE1C5;
                color: #49952E;
            }

            &.purple {
                background-color: #D9C0FC;
                color: #802DF6;
            }
        }

        &:first-child {
            padding-right: 10px;
            border-top-left-radius: 10px;
            border-end-start-radius: 10px;
        }
        &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}
  
tbody > tr:nth-child(odd) {
    background: #F1F1F1;
    border-radius: 8px;
}

@media screen and (max-width: 960px) {
    .table {
        td {
            &:first-child {
                border-radius: 0;
            }
            &:last-child {
                border-radius: 0;
            }
        }
    }
}
