#onetrust-banner-sdk {
  background: #7F2EF2 !important;
  box-shadow: 0px 4px 40px rgba(#090110, 0.25) !important;

  &:focus {
    outline: none !important;
  }

  &.otFloatingRoundedCorner {
    border-radius: 16px !important;
  }

  #onetrust-button-group-parent {
    padding: 0 25px 0 25px !important;

    button {
      height: 48px;
      border: 2px solid #FFFFFF !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      text-decoration: none !important;
      border-radius: 58px;

      &:focus, &:hover {
        outline-width: 0 !important;
        opacity: 1 !important;
      }
    }
  
    #onetrust-accept-btn-handler {
      background: #FFFFFF !important;
      margin: 5px 0 10px !important;
      color: #090211;
    }
  
    #onetrust-pc-btn-handler {
      background: transparent !important;
      color: #FFFFFF !important;
      margin-bottom: 20px !important;
    }

    #onetrust-button-group {
      button {
        width: 100%;
      }

      .banner-actions-container:only-child {
        #onetrust-accept-btn-handler {
          width: 120px;
          height: 36px;
          background: transparent !important;
          color: #FFFFFF;
          box-sizing: content-box !important; 
          padding: 0 !important;
          margin: 0 0 20px !important;
          border-radius: 20px !important;
          font-size: 16px !important;
          font-weight: 600 !important;
        }
      }
    }
  }

  #onetrust-group-container {
    margin-top: 0;

    #onetrust-policy {
      margin-top: 16px;

      .banner-header {
        display: none;
      }
  
      #onetrust-policy-text {
        padding: 0 20px 12px;
        margin-top: -20px;
        font-size: 14px;
        color: #FFFFFF;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.2px;

        a {
          font-weight: 700;
          color: #FFFFFF;

          &:focus {
            outline: none !important;
          }
        }
      }
    }
  }

  #onetrust-close-btn-container {
    display: none !important;
  }
}

.ot-floating-button__front {
  display: none !important;
}