@import "../../../variables";
@import "../../../mixins";

.container {
    position: relative;
    width: 100%;
    min-height: 139px;
    margin-top: 60px;
    margin-bottom: 60px;
    overflow: hidden;

    .wrapper {
        white-space: nowrap;
        position: absolute;
        top: 0;
        left: 0;

        img {
            transform: scale(0.9);
        }

        .content {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            -webkit-animation: slide 100s 0.1s linear infinite;
            animation: slide 100s 0.1s linear infinite;
        
            a { 
                display: inline-block;
                vertical-align: middle;
                padding: 0 30px;
                @include noSelect;
            }

            &.hoverable {
                &:hover {
                    -webkit-animation-play-state: paused;
                    animation-play-state: paused;
                }
            }
        }
    }
}
  
@keyframes slide {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-50%);
    }
}

@media (max-width: $breakpoint-desktop) {
    .container {
        margin-bottom: 50px;
    }
}

@media (max-width: $breakpoint-tablet) {
    .container {
        max-height: 80px;
        min-height: 70px;
        margin-top: 60px;
        margin-bottom: 20px;

        .wrapper {
            img {
                transform: scale(0.7);
            }
            .content {
                a {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}