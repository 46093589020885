@import "../../variables";

.wrapper {
  width: 100%;
  background-color: #100A16;
  color: white;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &.redTheme {
    background-color: #101010;
  }
}