@import "../../../../variables";

.header {
  .image {
    width: 100%;
    object-fit: contain;
  }

  &.red {
    .contentWrapper {
      .button {
        background-color: #FF0D38;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: -32% 20px 0;
    z-index: 2;

    .button {
      background-color: #45B8AF;
      background-image: none;
    }

    .title {
      max-width: 960px;
      margin-top: 30px;
      margin-bottom: 30px;
      font-weight: 300;
      white-space: pre-line;
    }
  }
}


@media (max-width: $breakpoint-mobile) {
  .header {
    .contentWrapper {
      margin-top: -77%;

      .logo {
        svg {
          width: 140px;
          height: 32px;
        }
      } 

      .title {
        font-size: 32px;
        line-height: 35px;
        margin: 20px 0;
      }
    }
  }
}