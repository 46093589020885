@import "../../../../variables";

.container {
  width: 100%;
  min-height: 78px;

  .inputContainer {
    display: flex;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 40px;
    height: 52px;

    .codeInput {
      background: transparent;
      height: auto;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2px;
      color: #FFFFFF;

      &.invalid {
        color: #E34646;
      }
    }

    .applyButton {
      flex: 1;
      height: 40px;
      margin: 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.2px;
      padding: 9px 15px;

      &:disabled {
        background:  linear-gradient(270deg, rgba(178, 80, 255, 0.3) 0%, rgba(128, 45, 246, 0.3) 100%);
      }
    }

    .deleteButton {
      margin: 13px 15px;
      cursor: pointer;
    }

    .checkMark {
      margin: 13px 15px;
    }
  }

  .error {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #E34646;
    margin: 0 20px;
    white-space: pre-line;
    
    a {
      text-decoration-color: #E34646;
      color: #E34646;
    }
  }
}


@media (min-width: $breakpoint-mobile) {
  .container {
    min-height: 90px;

    .inputContainer {
      height: 60px;

      .applyButton {
        height: 46px;
        margin: 7px;
        padding: 9px 20px;
      }
    }
  }
}